import React from "react";
import { Link } from "gatsby";
import startCase from "lodash/startCase";
import camelCase from "lodash/camelCase";

function createBreadCrumbs(location, url) {
  let crumbs = [];

  if (location?.pathname.includes("products") && url) {
    crumbs = url.split("/").filter((item) => item !== "");
    // remove empty value if any ...
    if (crumbs[0] === "") {
      crumbs.shift();
    }
  } else {
    crumbs = location?.pathname?.split("/").filter((item) => item !== "");
  }
  return crumbs;
}

function BreadCrumbs({ location, url, title }) {
  const crumbs = createBreadCrumbs(location, url);

  return (
    <>
      <ul className="breadcrumb-list">
        <li>
          <Link to="/">{`Home `}</Link>
        </li>
        {crumbs?.map((item, index) => (
          <li key={index}>
            <Link to={`/${crumbs.slice(0, index + 1).join("/")}`} key={item}>
              {` ${startCase(camelCase(item))}`}
            </Link>
          </li>
        ))}
      </ul>
      <h2>{title}</h2>
    </>
  );
}

export default BreadCrumbs;
