import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";

function simulateEvent(name, value) {
  return { target: { name, value } };
}

function CheckBox({ name, label, isChecked, count, onChange, ...props }) {
  const handleChange = (e) => {
    onChange && onChange(simulateEvent(name, e.target.checked));
  };

  return (
    <Field name={name} className="control-item">
      {({ field, form }) => (
        <label className="form-label form-label-facet">
          <input
            {...field}
            type="checkbox"
            onChange={handleChange}
            checked={isChecked}
            className="form-checkbox-facet"
          />
          <span className="checkbox-icon"></span>
          {label}
          {count && <span className="facet-count">{` (${count}) `}</span>}
        </label>
      )}
    </Field>
  );
}

CheckBox.prototype = {
  count: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
};

export default CheckBox;
