import React from "react";
import CertificateNav from "../components/account/certificate-nav";
import BreadCrumbs from "../components/common/breadcrumbs/BreadCrumbs";
import Layout from "../layout/Layout";
import TextField from "../components/form/TextField";
import { object, string } from "yup";
import { useFormik } from "formik";
import { getFormError, requiredMsg } from "../utils/form";
import TextArea from "../components/form/TextArea";
import { createOption } from "../utils/components";
import CheckBox from "../components/form/CheckBox";
import useCreateGiftCertificate from "../hooks/cart/useCreateGiftCertificate";

const validationSchema = object().shape({
  from_name: string().required(requiredMsg("first name")),
  from_email: string().required(requiredMsg("email")),
  to_name: string().required(requiredMsg("recipient name")),
  to_email: string().required(requiredMsg("recipient email")),
  amount: string().required(requiredMsg("recipient email")),
});

const initialValues = {
  from_name: "",
  from_email: "",
  to_name: "",
  to_email: "",
  amount: "",
  template: "",
  message: "",
};

const THEME_OPTIONS = [
  createOption("birthday", "Birthday"),
  createOption("boy", "Boy"),
  createOption("celebration", "Celebration"),
  createOption("christmas", "Christmas"),
  createOption("general", "General"),
  createOption("gril", "Gril"),
];

function GiftCertificate({ location }) {
  const { createCertificate } = useCreateGiftCertificate();
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      createCertificate(values);
    },
    enableReinitialize: true,
  });

  return (
    <Layout>
      <div className="page-wrapper">
        <div className="page-heading-section text-center page-block">
          <BreadCrumbs location={location} />
          <h1 className="page-title">GIFT CERTIFICATES</h1>
        </div>
      </div>
      <div className="container">
        <CertificateNav path={"gift-certificate"} />
        <form
          className="form create-account-form form-mid"
          onSubmit={formik.handleSubmit}
        >
          <div className="form-row form-row--half">
            <div className="form-field">
              <TextField
                id={"from-name"}
                name={"from_name"}
                label={"First Name"}
                labelFor={"first-name"}
                type={"input"}
                onChange={formik.handleChange}
                value={formik.values.from_name}
                error={getFormError(formik.errors, "from_name")}
                required
              />
            </div>

            <div className="form-field">
              <TextField
                id={"from-email"}
                name={"from_email"}
                label={"Your Email"}
                labelFor={"from-email"}
                type={"input"}
                onChange={formik.handleChange}
                value={formik.values.from_email}
                error={getFormError(formik.errors, "from_email")}
                required
              />
            </div>
            <div className="form-field">
              <TextField
                id={"to-name"}
                name={"to_name"}
                label={"Recipient's Name"}
                labelFor={"to-name"}
                type={"input"}
                onChange={formik.handleChange}
                value={formik.values.to_name}
                error={getFormError(formik.errors, "to_name")}
                required
              />
            </div>
            <div className="form-field">
              <TextField
                id={"to-email"}
                name={"to_email"}
                label={"Recipient's Email"}
                labelFor={"to-email"}
                type={"input"}
                onChange={formik.handleChange}
                value={formik.values.to_email}
                error={getFormError(formik.errors, "to_email")}
                required
              />
            </div>
            <div className="form-field">
              <TextField
                id={"amount"}
                name={"amount"}
                label={"Amount"}
                labelFor={"amount"}
                type={"input"}
                onChange={formik.handleChange}
                value={formik.values.amount}
                error={getFormError(formik.errors, "amount")}
                required
              />
            </div>
            <div className="form-field">
              <TextArea
                id={"message"}
                name={"message"}
                onChange={formik.handleChange}
                value={formik.values.message}
                label={"Optional Message"}
                labelFor={"message"}
              />
            </div>
            <div className="form-field">
              <CheckBox
                id={"checkbox-nonrefundable"}
                key={"nonn-refundable"}
                name={"checkbox"}
                value={formik.values.checkbox}
                label={"I agree that Gift Certificates are nonrefundable"}
                onChange={formik.handleChange}
              />
            </div>
            <div className="form-field" onChange={formik.handleChange}>
              {THEME_OPTIONS.map((item) => {
                return (
                  <div>
                    <input
                      type="radio"
                      value={item.value}
                      name={"template"}
                    ></input>
                    <label>
                      <span>{`${item.label}`}</span>
                    </label>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="form-actions text-center">
            <button type="submit" className="button">
              Add Gift Certificate To Cart
            </button>
          </div>
          <div className="form-actions text-center">
            <button className="button button--secondary button-small">
              preview
            </button>
          </div>
        </form>
      </div>
    </Layout>
  );
}

export default GiftCertificate;
