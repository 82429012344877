import React from "react";
import PropTypes from "prop-types";
import ErrorMessage from "./ErrorMessage";

function TextArea(
  {
    id,
    name,
    value,
    placeholder,
    onChange,
    label,
    labelFor,
    required,
    error,
    onBlur,
    touched,
    ...props
  },
  ref
) {
  return (
    <>
      {label && (
        <label className="form-label" htmlFor={labelFor}>
          {label}
          {required && <small>REQUIRED</small>}
        </label>
      )}

      <textarea
        id={id}
        name={name}
        placeholder={placeholder}
        value={value}
        className="form-input"
        spellCheck={false}
        onChange={onChange}
        ref={ref}
        onBlur={onBlur}
        {...props}
      />

      {error && touched && <ErrorMessage>{error}</ErrorMessage>}
    </>
  );
}

TextArea.prototype = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  labelFor: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.string,
  touched: PropTypes.bool,
};

TextArea.displayName = "TextArea";

export default React.forwardRef(TextArea);
